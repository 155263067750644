module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devsisters Policies","short_name":"Devsisters Policies","description":"Devsisters Policies","background_color":"#ffffff","theme_color":"#ff5f00","start_url":"/","lang":"ko","display":"minimal-ui","icon":"src/images/favicon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9dce5f969c7c2888a235a394755ba1a5"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KCD4QKF","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"LwdOU_leshML7k7fL-GkPQ","gtmPreview":"env-1","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
